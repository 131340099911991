var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-5"},[_c('div',{staticClass:"mt-5"},[_c('b-img',{staticClass:"mb-2",staticStyle:{"max-height":"100px","max-width":"400px"},attrs:{"src":_vm.$store.getters.environmentLogoURL,"fluid":"","onerror":"this.style.display='none'"}})],1),_c('b-form',{staticClass:"w-75 text-left",staticStyle:{"margin":"auto"},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit($event)}}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"userEmail-input"}},[_c('b-form-input',{attrs:{"id":"userEmail-input","name":"userEmail-input","state":_vm.validateState('userEmail'),"aria-describedby":"userEmail-feedback"},model:{value:(_vm.$v.form.userEmail.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.userEmail, "$model", $$v)},expression:"$v.form.userEmail.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"userEmail-feedback"}},[_vm._v("Please enter a valid email")])],1),_c('b-form-group',{attrs:{"label":"Password","label-for":"userPassword-input"}},[_c('b-form-input',{attrs:{"id":"userPassword-input","name":"userPassword-input","aria-describedby":"userPassword","type":"password"},model:{value:(_vm.form.userPassword),callback:function ($$v) {_vm.$set(_vm.form, "userPassword", $$v)},expression:"form.userPassword"}}),_c('b-form-invalid-feedback',{attrs:{"id":"userPassword-feedback"}},[_vm._v("Password is Required")])],1),_c('div',{staticClass:"text-center"},[_c('b-button',{style:({
          color: 'white',
          borderColor: 'white',
          right: ' 10px !important',
        }),attrs:{"type":"submit","variant":"outline"}},[_vm._v("Sign In")]),_c('b-button',{staticClass:"ml-2",style:({
          color: 'white',
          borderColor: 'white',
          right: ' 10px !important',
        }),attrs:{"variant":"outline"},on:{"click":_vm.resetPassword}},[_vm._v("Forgot Password ?")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }