<template>
  <div>
    <SignIn v-if="!signedIn" />
    <Library v-else />
  </div>
</template>

<script>
import SignIn from "../containers/SignIn"
import Library from "../containers/Library"
export default {
  name: "Index",
  data() {
    return {}
  },
  components: {
    SignIn,
    Library,
  },
  computed: {
    signedIn() {
      return this.$store.state.user.userState === "signedIn"
    },
  },
}
</script>