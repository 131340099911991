import Vue from "vue"

export function setCookie(value, key) {
  Vue.$cookies.set(
    key, // keyName
    value, // value
    "999d", // expireTimes
    null, //path
    null, // domain
    process.env.PROD ? true : null, //secure
    "Lax", // sameSite
  )
}

export function removeCookie(key) {
  return Vue.$cookies.remove(key)
}
