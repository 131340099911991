import Vue from "vue"
import App from "./App.vue"
import { BootstrapVue, IconsPlugin } from "bootstrap-vue"
import router from "./router"
import { store } from "./store"
import VueCookies from "vue-cookies"
import { api } from "./utils/api"
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
import "video.js/dist/video-js.css"
import mitt from "mitt"

const emitter = mitt()

Vue.prototype.$emitter = emitter

Vue.prototype._emit = (type, event) => {
  return emitter.emit(type, event)
}

Vue.prototype._on = (type, handler) => {
  return emitter.on(type, handler)
}

Vue.prototype._off = (type, handler) => {
  return emitter.off(type, handler)
}

Vue.config.productionTip = false
let { state } = store

Vue.prototype.$api = (route, body) => {
  return api(route, body, false, state)
}

Vue.prototype.$apiPublic = (route, body) => {
  return api(route, body, true, state)
}

Vue.use(VueCookies)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app")
