<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <div
      class="position-relative"
      style="min-height: 100vh; overflow-x: hidden"
    >
      <Header />
      <b-container style="margin-bottom: 80px">
        <b-form-input
          class="mb-3"
          v-if="filteredContents !== 'loading' && (ownedContentsCount >= 30)"
          v-model="searchString"
          debounce="300"
          variant="dark"
          :placeholder="placeholder"
          type="search"
        />
        <b-row
          deck
          v-if="$store.state.loading"
          class="text-center justify-content-center mt-5"
        >
          <b-spinner />
        </b-row>
        <div v-else-if="filteredContents.length">
          <b-row deck class="text-center justify-content-center py-2">
              <b-col
                xs="12"
                sm="4"
                md="3"
                lg="2"
                xl="2"
                v-for="(item, i) in itemsToRender"
                :key="i"
                class="my-1"
              >
                <Content :item="item" class="h-100" />
              </b-col>
          </b-row>
        </div>
        <div class="h3 text-center" v-else>
          Sorry, No Content Found
        </div>
      </b-container>
      <Footer />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex"
import fuzzy from "fuzzy"
import infiniteScroll from "vue-infinite-scroll"

const options = {
  extract: (el) => {
    return el.contentName || el.byLine || el.shortBlurb
  },
}
let count = 0
let contentsPerLoad = 24

export default {
  directives: { infiniteScroll },
  components: {
    Content: () => import("../components/Content"),
    Header: () => import("../components/Header"),
    Footer: () => import("../components/Footer"),
  },
  name: "Library",
  data() {
    return {
      searchString: "",
      itemsToRender: [],
      busy: false,
    }
  },
  computed: {
    placeholder() {
      return `Search ${this.filteredContents.length} items`
    },
    ...mapState(["eJSON"]),
    contents() {
      return this.$store.state.contentForEnvironment?.content
    },
    ownedContentsCount () {
       return this.contents?.filter(
        (e) => e.playable && e.userActiveAccess && !e.bundleContent,
      )?.length
    },
    filteredContents() {
      let contents = this.contents
      if (!contents?.length) {
        return []
      }
      contents = contents.filter(
        (e) => e.playable && e.userActiveAccess && !e.bundleContent,
      )

      if (!this.searchString?.trim()) {
        return contents
      }

      let result = fuzzy.filter(this.searchString?.trim(), contents || [], options)
      return result ? result.map((found) => found.original) : []
    },
  },

  watch: {
    filteredContents: function () {
      this.loadMore()
    },
    searchString: function (newVal) {
      this.itemsToRender = []
      count = 0
      this.loadMore()
      if (newVal?.trim()) {
        this.$apiPublic("remember/it", {
          logType: "filterByString",
          description: `user filtered content by string: ${this.searchString}`,
          context: "library",
          searchString: this.searchString,
        })
      }
    },
  },
  methods: {
    loadMore() {
      if (
        Array.isArray(this.filteredContents) &&
        count < this.filteredContents.length
      ) {
        this.busy = true
        let itemsToAdd = this.filteredContents.slice(
          count,
          count + contentsPerLoad,
        )
        count += contentsPerLoad
        this.itemsToRender.push(...itemsToAdd)
        this.busy = false
      }
    },
  },
}
</script>